import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './headerprincipal.css';

import logo from '../../imagens/var/logo.png';
import { FiMenu } from 'react-icons/fi';
import { RiCloseLine } from 'react-icons/ri';
import api from '../../services/api';

import { FaHome, FaMousePointer, FaDonate, FaUserPlus, FaShoppingBag, FaUber, FaUser } from 'react-icons/fa';

import Cadastro from '../../paginas/Planos';

export default function HeaderPrincipal() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [currentScreen, setCurrentScreen] = useState('');
    const [secaoInicioLogo, setSecaoInicioLogo] = useState([]);
    const location = useLocation();

    const handleScreenChange = (screenName) => {
        setCurrentScreen(screenName);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    const handleObter = async () => {
        try {
            const response = await api.get('Estilizacao/ObterInicio');
            const responseData = response.data;

            const logoFiltrado = responseData.filter(item => item.codigoSecao === 1).filter(item => item.tipo === "Imagem Logo");
            setSecaoInicioLogo(logoFiltrado);
        } catch (error) {
            console.error('Erro ao obter dados:', error);
        }
    };

    useEffect(() => {
        handleObter();
    }, []);

    return (
        <div className='header-principal'>
            <Link to={`/`}><img style={{ width: '180px' }} src={logo} alt="Logo"/></Link>
            
            <section style={{ display: 'flex', gap: '5px' }}>
                <Link id='header-web' to={`/`} className={location.pathname === '/' ? 'active' : 'hover'} onClick={() => handleScreenChange('Home')} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}><FaHome />Home</Link>
                <a 
                    href="https://shop.parabank.com.br/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className={location.pathname === '/planos' ? 'active' : 'hover'} 
                    onClick={() => handleScreenChange('Quero participar')}
                    style={{ display: 'flex', alignItems: 'center', gap: '4px', fontSize: '16px' }}
                >
                    <FaShoppingBag size={15} />
                    Marketplace
                </a>
            </section>

            <div className='buttons-container'>
                <Link id='header-web' to={`/login`}>
                    <button style={{
                        color: '#FFFFFF',
                        backgroundColor: '#5a498c',
                        height: '55px',
                        borderRadius: '30px',
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        fontSize: '16px',
                        margin: 0
                    }}>
                        <FaUser /> Entrar
                    </button>
                </Link>

                <Link id='header-web' to={`/planos`}>
                    <button style={{
                        color: '#FFFFFF',
                        backgroundColor: '#000000',
                        height: '55px',
                        borderRadius: '30px',
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                        border: '1px solid #000000',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        fontSize: '16px',
                        margin: 0
                    }}>
                        Cadastrar-se
                    </button>
                </Link>
            </div>

            <FiMenu id='header-mobile' onClick={toggleDropdown} />
            <div ref={dropdownRef} className={`dropdown-overlay ${dropdownOpen ? 'open slide-in' : ''}`}>
                <div><RiCloseLine onClick={closeDropdown} /></div>
                <section>
                    <Link to={`/`}>Home <FaHome /></Link>
                    <Link to={`/planos`}>Matricule-se <FaMousePointer /></Link>
                    <Link to={`/doar`}>Doar <FaDonate /></Link>
                    <Link to={`/adote-um-aluno`}>Adotar <FaUserPlus /></Link>
                    <Link to={`/login`}><button>Entrar</button></Link>
                </section>
            </div>
        </div>
    );
}

